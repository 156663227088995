import * as React from 'react'
import { useDropzone } from 'react-dropzone'
import clsx from 'clsx'
import { Button, FormHelperText, FormLabel } from '@mui/material'
import styles from './FileInput.module.css'
import { FileInputProps } from './types'
import { fromFileList } from './utils'
import { FlexContainer } from '@layout/FlexContainer'
import addPhoto from '@assets/images/form/car-photo.svg'

export const FileInput: React.FC<FileInputProps> = ({
  error,
  errorText,
  label,
  inputVisible,
  options: { inputText, inputTextDragActive = inputText, dropzoneOptions },
  onFilesSelected,
  children,
  ...props
}) => {
  const defaultId = React.useId()
  const id = props.id ?? defaultId

  const handleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      e.preventDefault()
      onFilesSelected(fromFileList(e.target.files))
    },
    [onFilesSelected]
  )

  const onDrop = React.useCallback(
    (files: Array<File>) => {
      onFilesSelected(files)
    },
    [onFilesSelected]
  )

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    disabled: props.disabled, // disable drop zone in case the whole control is disabled
    multiple: props.multiple,
    ...dropzoneOptions,
    onDrop
  })

  return (
    <FlexContainer vertical responsiveGap className={styles.fileInputContainer}>
      {label && (
        <FormLabel id={`${id}-label`} htmlFor={id}>
          {label}
        </FormLabel>
      )}
      {inputVisible !== false && (
        <div
          className={clsx({
            fileUploadDropZone: true,
            dragging: isDragActive,
            dragAccept: isDragAccept,
            dragReject: isDragReject
          })}
          {...getRootProps()}
        ><>
          <Button className={styles.addPhoto__button} disabled={props.disabled} variant="text" component="label" onClick={(e) => e.preventDefault()}>
            <img src={addPhoto} alt="eu Badge" loading="lazy" className={styles.addPhoto__image} />
            {isDragActive ? inputTextDragActive : inputText}
            <input
              id={id}
              aria-labelledby={`${id}-label`}
              aria-invalid={error}
              aria-required={props.required}
              aria-errormessage={errorText}
              aria-describedby={error ? `${id}-error` : undefined}
              hidden={true}
              //ref={field.ref}
              onChange={handleChange}
              type="file"
              disabled={props.disabled}
              {...getInputProps()}
              {...props}
            />
          </Button>
        </>
          {error && (
            <FormHelperText id={`${id}-error`} error={true}>
            {errorText}
            </FormHelperText>
          )}
        </div>
      )}
      {children}
    </FlexContainer>
  )
}
