import { DropzoneOptions } from 'react-dropzone'
import { NullableType, hasValue } from '@digital-magic/ts-common-utils'

export const fromFileList = (files: NullableType<FileList>): Array<File> => {
  const result: Array<File> = []
  if (hasValue(files)) {
    // eslint-disable-next-line functional/no-loop-statements
    for (const file of files) {
      // eslint-disable-next-line functional/immutable-data
      result.push(file)
    }
  }
  return result
}

export const defaultImageDropzoneOptions: DropzoneOptions = {
  accept: {
    'image/png': ['.png'],
    'image/jpeg': ['.jpg', '.jpeg']
  },
  multiple: true
}
