import { FN, Handler, hasValue } from '@digital-magic/ts-common-utils'

export const uploadFilesList =
  <TUploadResult>(
    upload: FN<File, Promise<TUploadResult>>,
    onFileUploadSuccess?: Handler<void>,
    onFileUploadError?: Handler<unknown>
  ): FN<Array<File>, Promise<Array<TUploadResult>>> =>
  (files) =>
    Promise.all(
      files.map((file) =>
        upload(file)
          .then((result) => {
            onFileUploadSuccess?.()
            return result
          })
          .catch((e) => {
            // eslint-disable-next-line no-console
            console.error('File upload error', e)
            onFileUploadError?.(e)
            return undefined
          })
      )
    ).then((result) => result.filter(hasValue))
